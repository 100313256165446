// 文件系统相关参数
const protocol = 'http';
const domain = 'timebox.space';
const config = {
    'serverWeb': protocol + '://www.' + domain,
    'serverAdmin': protocol + '://tq.' + domain,
    'serverApi': protocol + '://api.' + domain,
    'serverImage': protocol + '://image.' + domain
};

function nl2br (str, isXhtml) {
    //  discuss at: https://locutus.io/php/nl2br/
    // original by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Philip Peterson
    // improved by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Atli Þór
    // improved by: Brett Zamir (https://brett-zamir.me)
    // improved by: Maximusya
    // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // bugfixed by: Reynier de la Rosa (https://scriptinside.blogspot.com.es/)
    //    input by: Brett Zamir (https://brett-zamir.me)
    //   example 1: nl2br('Kevin\nvan\nZonneveld')
    //   returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
    //   example 2: nl2br("\nOne\nTwo\n\nThree\n", false)
    //   returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
    //   example 3: nl2br("\nOne\nTwo\n\nThree\n", true)
    //   returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
    //   example 4: nl2br(null)
    //   returns 4: ''

    // Some latest browsers when str is null return and unexpected null value
    if (typeof str === 'undefined' || str === null) {
        return ''
    }

    // Adjust comment to avoid issue on locutus.io display
    var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'

    return (str + '')
        .replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
}

// 获取QueryString中指定参数
function getQueryVariable(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return '';
}

// 获取QueryString中所有参数
function parseQueryString() {
    let json = {};
    let url = window.location.search;
    if (url) {
        let arr = url.substr(url.indexOf('?') + 1).split('&');
        arr.forEach(item=>{
            let tmp = item.split('=');
            json[tmp[0]] = tmp[1];
        });
    }
    return json;
}

export default {
    protocol,
    domain,
    config,
    nl2br,
    getQueryVariable,
    parseQueryString
}
