import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
    // 网页标题
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = "时光盒子";
    }
    next();
});

export default router
