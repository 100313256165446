<template>
    <div id="app">
        <router-view/>
        <alertAnimate/>
    </div>
</template>

<script>
    import alertAnimate from "./components/alert_animate";

    export default {
        name: 'app',
        components: { alertAnimate }
    }
</script>
